import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, MenuItem, OutlinedInput, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { AdminContext } from '../../context/AdminContext';
import KanjiBox from './KanjiBox';
import KanjiDialog from './KanjiDialog';
import KanjiWordsDialog from './KanjiWordsDialog';
import BulkUploadDialog from './BulkUploadDialog';
import { tokens } from '../../theme';
import * as XLSX from 'xlsx';
import LoadingBarDialog from '../../components/LoadingBarDialog';


function KanjisPage() {
    const colors = tokens()
    const { token, admin } = useContext(AdminContext);
    const [searchKey, setSearchKey] = useState("");
    const [level, setLevel] = useState(0);
    const [kanjiCounts, setKanjiCounts] = useState({ "n1": 0, "n2": 0, "n3": 0, "n4": 0, "n5": 0 });
    const [kanjis, setKanjis] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [kanjiWordsDialogOpen, setKanjiWordsDialogOpen] = useState(false);
    const [targetKanji, setTargetKanji] = useState(null);
    const [targetKanjiForWords, setTargetKanjiForWords] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [bulkUploadDialogOpen, setBulkUploadDialogOpen] = useState(false);
    const [kanjiToRemove, setKanjiToRemove] = useState(null);
    const [loading, setLoading] = useState(false);

    const fetchKanjiCounts = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getKanjiCounts?token=${token}&countPassives=1`)
            .catch((err) => {
                console.log("err: " + err);
                setKanjiCounts({ "n1": 0, "n2": 0, "n3": 0, "n4": 0, "n5": 0 });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (response.data.kanjiCounts && response.data.kanjiCounts.length > 0) {
                        const getKanjiCount = (kanjiCounts, level) => {
                            var count = kanjiCounts.find((c) => c.level == level) || { kanji_count: 0 };
                            return count.kanji_count;
                        }
                        setKanjiCounts(
                            {
                                "n1": getKanjiCount(response.data.kanjiCounts, 1),
                                "n2": getKanjiCount(response.data.kanjiCounts, 2),
                                "n3": getKanjiCount(response.data.kanjiCounts, 3),
                                "n4": getKanjiCount(response.data.kanjiCounts, 4),
                                "n5": getKanjiCount(response.data.kanjiCounts, 5)
                            }
                        );
                    } else {
                        setKanjiCounts({ "n1": 0, "n2": 0, "n3": 0, "n4": 0, "n5": 0 });
                    }
                }
            });
    }

    const fetchKanjis = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getKanjis?token=${token}&searchKey=${searchKey}&level=${level}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                setKanjis([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.kanjis && response.data.kanjis.length === 50);
                    if (startIndex === 0) {
                        setKanjis(response.data.kanjis);
                        fetchKanjiCounts()
                    } else {
                        setKanjis((prev) => [...prev, ...response.data.kanjis]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchKanjis();
    }, [startIndex]);

    const handleFormSubmit = async (values) => {
        if (values.kanjiImage) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadKanjiImage?token=${token}`, {
                kanjiImage: values.kanjiImage
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete values.kanjiImage;
                        values.kanji_image_path = response.data.contentPath;
                    }
                });
        }
        if (values.strokeOrderGif) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadKanjiStrokeOrderGif?token=${token}`, {
                kanjiStrokeOrderGif: values.strokeOrderGif
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete values.strokeOrderGif;
                        values.stroke_order_gif_path = response.data.contentPath;
                    }
                });
        }
        delete values.kanjiImage;
        if (values && values.kanji_image_link) {
            delete values.kanji_image_link;
        }
        delete values.strokeOrderGif;
        if (values && values.stroke_order_gif_link) {
            delete values.stroke_order_gif_link;
        }
        if (targetKanji) {
            if (admin.permitted_language) {
                values.translations = [...(targetKanji.translations.filter((tra) => tra.language !== admin.permitted_language && tra.language !== "en")), ...(values.translations)];
            }
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateKanjiWithTranslations?token=${token}&kanjiId=${targetKanji.id}`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    if (startIndex === 0) {
                        fetchKanjis();
                    } else {
                        setStartIndex(0);
                    }
                }
            });
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createKanji?token=${token}`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    if (startIndex === 0) {
                        fetchKanjis();
                    } else {
                        setStartIndex(0);
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (targetKanji) {
            setDialogOpen(true);
        }
    }, [targetKanji]);

    useEffect(() => {
        if (targetKanjiForWords) {
            setKanjiWordsDialogOpen(true);
        }
    }, [targetKanjiForWords]);

    const handleClose = async () => {
        setDialogOpen(false);
        setTargetKanji(null);
    }

    const handleKanjiWordsDialogClose = async () => {
        setKanjiWordsDialogOpen(false);
        setTargetKanjiForWords(null);
        handleClose();
    }

    const updateKanji = (k) => {
        setTargetKanji(k);
    }

    const openKanjiWordsDialog = (kanji) => {
        setTargetKanjiForWords(kanji);
    }

    useEffect(() => {
        if (kanjiToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [kanjiToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setKanjiToRemove(null);
    }

    const handleKanjiToRemove = (k) => {
        if (k) {
            setKanjiToRemove(k);
        }
    }

    const removeKanji = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteKanji?token=${token}&kanjiId=${kanjiToRemove.id}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                if (startIndex === 0) {
                    fetchKanjis();
                } else {
                    setStartIndex(0);
                }
                handleRemoveDialogClose();
            }
        });
    }

    const exportKanjis = async () => {
        setLoading(true);
        axios.get(
            `${process.env.REACT_APP_API_URL}webAdmin/getAllKanjiDatasToExport?token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setLoading(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    const worksheetApprovers = XLSX.utils.json_to_sheet(response.data.kanjis);
                    const workbook = XLSX.utils.book_new();
                    XLSX.utils.book_append_sheet(workbook, worksheetApprovers, "KanjisDatas");
                    XLSX.writeFile(workbook, `Obodon-Datas-${(new Date()).toISOString()}.${"xlsx"}`);
                    setLoading(false);
                }
            });
    };

    return (
        <Box sx={{ p: "75px" }}>
            {loading && <LoadingBarDialog loading={loading} />}
            <BulkUploadDialog dialogOpen={bulkUploadDialogOpen} handleClose={() => {
                setBulkUploadDialogOpen(false);
                if (startIndex === 0) {
                    fetchKanjis();
                } else {
                    setStartIndex(0);
                }
            }} />
            <KanjiDialog targetKanji={targetKanji} dialogOpen={dialogOpen} handleClose={handleClose} handleFormSubmit={handleFormSubmit} />
            {targetKanjiForWords && <KanjiWordsDialog targetKanji={targetKanjiForWords} dialogOpen={kanjiWordsDialogOpen} handleClose={handleKanjiWordsDialogClose} />}

            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Kanjiyi silmek istediğinizden emin misiniz?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Kanji ve ilgili tüm veriler (çeviriler vb.) silinecektir. Bu işlemi geri alamazsınız, dilerseniz aynı kanjiyi tekrar ekleyebilirsiniz.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Vazgeç</Button>
                    <Button onClick={() => removeKanji()}>Sil</Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title="Kanjiler" subtitle="Kanjileri bu sayfadan görebilir ve yönetebilirsiniz." />

                {!(admin.permitted_language) &&
                    <Button variant='contained' color='success' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        setDialogOpen(true);
                    }} >
                        Yeni Kanji Ekle
                    </Button>
                }

                {!(admin.permitted_language) &&
                    <Button variant='contained' color='warning' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        setBulkUploadDialogOpen(true);
                    }} >
                        Toplu Kanji Ekle
                    </Button>
                }

                <Button variant='contained' color='info' sx={{ m: 1, width: '25ch' }} onClick={() => {
                    window.location.href = "/kanjiWords";
                }} >
                    Kelimeler
                </Button>



                {!(admin.permitted_language) &&
                    <Button variant='contained' color='info' sx={{ m: 1, width: '25ch' }} onClick={() => {
                        exportKanjis();
                    }} >
                        Dışa Aktar
                    </Button>
                }

            </Box>
            <Box display="flex" alignItems="center" gap="20px">
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="search-box">Ara</InputLabel>
                    <OutlinedInput
                        id="search-box"
                        type='text'
                        onChange={(e) => {
                            setSearchKey(e.target.value ? e.target.value : "");
                        }}
                        label="Password"
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel id="filter-level">Seviye</InputLabel>
                    <Select
                        labelId="filter-level"
                        label="Seviye"
                        value={level}
                        onChange={(e) => {
                            setLevel(e.target.value ? e.target.value : 0);
                        }}
                    >
                        <MenuItem value={0}>Tümü {"(" + (Object.values(kanjiCounts).reduce((accumulator, currentValue) => { return accumulator + currentValue }, 0)) + ")"}</MenuItem>
                        <MenuItem value={1}>N1 {"(" + (kanjiCounts.n1) + ")"}</MenuItem>
                        <MenuItem value={2}>N2 {"(" + (kanjiCounts.n2) + ")"}</MenuItem>
                        <MenuItem value={3}>N3 {"(" + (kanjiCounts.n3) + ")"}</MenuItem>
                        <MenuItem value={4}>N4 {"(" + (kanjiCounts.n4) + ")"}</MenuItem>
                        <MenuItem value={5}>N5 {"(" + (kanjiCounts.n5) + ")"}</MenuItem>
                    </Select>
                </FormControl>
                <IconButton
                    aria-label="search button"
                    sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                    onClick={() => {
                        setKanjis([]);
                        if (startIndex === 0) {
                            fetchKanjis();
                        } else {
                            setStartIndex(0);
                        }
                    }}
                    edge="end"
                >
                    <SearchIcon />
                </IconButton>
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={kanjis.length}
                    next={() => {
                        setStartIndex(kanjis.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Tüm kanjileri görüntülüyorsunuz</b>
                        </p>
                    }
                >
                    <Box display="flex" flexWrap="wrap" gap="20px">
                        {kanjis.map(k =>
                            <KanjiBox key={k.id + "-kanji"} kanji={k} updateKanji={updateKanji} removeKanji={handleKanjiToRemove} openKanjiWords={openKanjiWordsDialog} searchKey={searchKey} />
                        )}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default KanjisPage
