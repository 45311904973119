import { Box, IconButton, Menu, MenuItem } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { tokens } from '../../../../theme';
import { AdminContext } from '../../../../context/AdminContext';
import CopyCheckTypography from '../../../../components/CopyCheckTypography';

function KanjiWordBox({ kanjiWord, updateKanjiWord, removeKanjiWord, increasePosition, decreasePosition, searchKey }) {
    const { token, admin } = useContext(AdminContext);
    const colors = tokens();
    const [anchorEl, setAnchorEl] = useState(null);
    const [translations, setTranslations] = useState([]);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        fetchTranslations(kanjiWord.id);
    }, [kanjiWord])

    const fetchTranslations = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getKanjiWordTranslations?token=${token}&kanjiWordId=${kanjiWord.id}`)
            .catch((err) => {
                console.log("err: " + err);
                setTranslations([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setTranslations(response.data.translations);
                }
            });
    }

    return (
        <Box sx={{ gridColumn: "span 1" }}>
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    updateKanjiWord({ ...kanjiWord, translations: translations });
                }}>
                    Düzenle
                </MenuItem>
                {!!decreasePosition &&
                    <MenuItem onClick={() => {
                        handleClose();
                        decreasePosition({ ...kanjiWord, translations: translations });
                    }}>
                        Üste taşı
                    </MenuItem>
                }
                {!!increasePosition &&
                    <MenuItem onClick={() => {
                        handleClose();
                        increasePosition(kanjiWord);
                    }}>
                        Alta taşı
                    </MenuItem>
                }
                {admin.content_deletion_perm === 1 &&
                    <MenuItem onClick={() => {
                        handleClose()
                        removeKanjiWord(kanjiWord);
                    }}>
                        Sil
                    </MenuItem>
                }
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>
                </Box>
                <Box>
                    <CopyCheckTypography variant='h3' fontWeight="600" color={searchKey && kanjiWord.kanji_word.startsWith(searchKey) ? "orange" : null}>
                        {kanjiWord.kanji_word}
                    </CopyCheckTypography>
                </Box>
                <Box mt="10px">
                    <Box>
                        <CopyCheckTypography variant='h6' fontWeight={600} sx={{ textDecoration: "underline" }}>Kelime Okunuşu</CopyCheckTypography>
                        <CopyCheckTypography variant='h6' >{kanjiWord.hir}</CopyCheckTypography>
                    </Box>
                    <Box>
                        <CopyCheckTypography variant='h6' fontWeight={600} sx={{ textDecoration: "underline" }}>Cümle</CopyCheckTypography>
                        <CopyCheckTypography variant='h6' >{kanjiWord.sentence}</CopyCheckTypography>
                    </Box>
                    <Box>
                        <CopyCheckTypography variant='h6' fontWeight={600} sx={{ textDecoration: "underline" }}>Cümle Okunuşu</CopyCheckTypography>
                        <CopyCheckTypography variant='h6' >{kanjiWord.pronunciation}</CopyCheckTypography>
                    </Box>
                </Box>
                <Box component="hr" my="10px" border={"1px solid " + colors.grey[500]} />
                <Box>
                </Box>
                <Box mt="15px" display="grid" gap="10px" gridTemplateColumns="repeat(2, minmax(0, 1fr))">
                    <CopyCheckTypography sx={{ textDecoration: "underline", gridColumn: "span 2", textAlign: "center" }} variant='h4'><b>Kelime Çevirileri</b></CopyCheckTypography>
                    {(admin.permitted_language ? translations.filter((tra) => tra.language === "en" || tra.language === admin.permitted_language) : translations).map(tra =>
                        <Box key={"tra-word-" + tra.language} sx={{ gridColumn: "span 1" }}>
                            <CopyCheckTypography variant='h5' color={searchKey && tra.translation.startsWith(searchKey) ? "orange" : null}>{tra.language.toUpperCase() + ": " + tra.translation}</CopyCheckTypography>
                        </Box>
                    )}
                </Box>
                <Box mt="15px" display="grid" gap="10px" gridTemplateColumns="repeat(2, minmax(0, 1fr))">
                    <CopyCheckTypography sx={{ textDecoration: "underline", gridColumn: "span 2", textAlign: "center" }} variant='h4'><b>Cümle Çevirileri</b></CopyCheckTypography>
                    {(admin.permitted_language ? translations.filter((tra) => tra.language === "en" || tra.language === admin.permitted_language) : translations).map(tra =>
                        <Box key={"tra-sentence-" + tra.language} sx={{ gridColumn: "span 2" }}>
                            <CopyCheckTypography variant='h5' color={searchKey && tra.sentence_translation.includes(searchKey) ? "orange" : null}>{tra.language.toUpperCase() + ": " + tra.sentence_translation}</CopyCheckTypography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default KanjiWordBox