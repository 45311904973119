import { Box, IconButton, useTheme } from '@mui/material'
import React, { useContext, useState } from 'react'
import { tokens } from '../theme';
import NotificationAddIcon from '@mui/icons-material/NotificationAdd';
// import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import LogoutIcon from '@mui/icons-material/Logout';
import { AdminContext } from '../context/AdminContext';
import NotificationDialog from '../components/NotificationDialog';
// import SettingsMenu from '../components/SettingsMenu';

function Topbar() {
    const { setToken, admin } = useContext(AdminContext);
    const theme = useTheme();
    const colors = tokens();

    const [notificationDialogOpen, setNotificationDialogOpen] = useState(false);

    // const [anchorEl, setAnchorEl] = useState(null);
    // const open = Boolean(anchorEl);
    // const handleClick = (event) => {
    //     setAnchorEl(event.currentTarget);
    // };

    return (
        <Box position="absolute" right="0" display="flex" gap="10px" pt="10px" pr="20px" justifyContent="end" alignItems="center" zIndex="5">
            <NotificationDialog dialogOpen={notificationDialogOpen} handleClose={() => setNotificationDialogOpen(false)} />
            {/* <SettingsMenu anchorEl={anchorEl} open={open} handleClose={() => setAnchorEl(null)} /> */}
            {admin.notifications_perm === 1 &&
                <IconButton onClick={() => setNotificationDialogOpen(true)}>
                    <NotificationAddIcon />
                </IconButton>
            }
            {/* {admin.settings_perm === 1 &&
                <IconButton
                    id="open-menu-button"
                    aria-controls={open ? 'menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}>
                    <AppSettingsAltIcon />
                </IconButton>} */}
            <IconButton onClick={() => setToken(null)}>
                <LogoutIcon />
            </IconButton>
        </Box>
    )
}

export default Topbar