import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material';
import { AdminContext } from '../../../context/AdminContext';
import KanjiWordBox from './KanjiWordBox';
import axios from 'axios';
import KanjiWordDialog from './KanjiWordDialog';

function KanjiWordsDialog({ targetKanji, dialogOpen, handleClose }) {
    const { token, admin } = useContext(AdminContext);
    const [kanjiWords, setKanjiWords] = useState([]);

    const [targetKanjiWord, setTargetKanjiWord] = useState(null);
    const [kanjiWordDialogOpen, setKanjiWordDialogOpen] = useState(false);
    const [kanjiWordToRemove, setKanjiWordToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    const fetchKanjiWords = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getKanjiWords?token=${token}&kanjiId=${targetKanji.id}`)
            .catch((err) => {
                console.log("err: " + err);
                setKanjiWords([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setKanjiWords(response.data.kanjiWords);
                }
            });
    }

    useEffect(() => {
        fetchKanjiWords();
    }, []);

    const handleFormSubmit = async (values, sendNotification, kanji) => {
        if (targetKanjiWord) {
            if (admin.permitted_language) {
                values.translations = [...(targetKanjiWord.translations.filter((tra) => tra.language !== admin.permitted_language && tra.language !== "en")), ...(values.translations)];
            }
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateKanjiWordWithTranslations?token=${token}&kanjiWordId=${targetKanjiWord.id}`, {
                ...values
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    fetchKanjiWords();
                }
            });
        } else {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/createKanjiWord?token=${token}`, {
                ...values, kanji_id: targetKanji.id, position: kanjiWords && kanjiWords.length > 0 ? kanjiWords.length + 1 : 1
            }).catch((err) => {
                console.log("err: " + err);
            }).then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    handleClose();
                    fetchKanjiWords();
                    if (sendNotification) {
                        axios.post(`${process.env.REACT_APP_API_URL}webAdmin/sendNewContentNotification?token=${token}`, {
                            kanji: kanji,
                            newWord: { ...values, id: response.data.kanjiWordId }
                        }).catch((err) => {
                            console.log("err: " + err);
                        }).then((response) => {
                            if (response && response.data.success === true && response.data.status === 201) {
                                //success
                            }
                        });
                    }
                }
            });
        }
    }

    useEffect(() => {
        if (targetKanjiWord) {
            setKanjiWordDialogOpen(true);
        }
    }, [targetKanjiWord]);

    const handleKanjiWordDialogClose = async () => {
        setKanjiWordDialogOpen(false);
        setTargetKanjiWord(null);
    }
    const updateKanjiWord = (w) => {
        setTargetKanjiWord(w);
    }

    useEffect(() => {
        if (kanjiWordToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [kanjiWordToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setKanjiWordToRemove(null);
    }

    const handleKanjiWordToRemove = (w) => {
        if (w) {
            setKanjiWordToRemove(w);
        }
    }

    const removeKanjiWord = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteKanjiWord?token=${token}&kanjiWordId=${kanjiWordToRemove.id}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                if (kanjiWordToRemove.position !== kanjiWords.length) {
                    for (let i = kanjiWordToRemove.position; i < kanjiWords.length; i++) {
                        const kw = kanjiWords[i];
                        axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateKanjiWord?token=${token}&kanjiWordId=${kw.id}`, {
                            position: kw.position - 1
                        }).catch((err) => {
                            console.log("err: " + err);
                        }).then((response) => {
                            if (response && response.data.success === true && response.data.status === 201) {
                                if (i === kanjiWords.length - 1)
                                    fetchKanjiWords();
                            }
                        });
                    }
                }
                fetchKanjiWords();
                handleRemoveDialogClose();
            }
        });
    }

    const increasePosition = async (kanjiWord) => {
        if (kanjiWords.length >= 2 && kanjiWord.position !== kanjiWords.length) {
            var kanjiWordIx = kanjiWords.findIndex(kw => kw.id === kanjiWord.id);
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateKanjiWord?token=${token}&kanjiWordId=${kanjiWord.id}`, {
                position: kanjiWord.position + 1
            }).catch(async (err) => {
                console.log("err: " + err);
            }).then(async (response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateKanjiWord?token=${token}&kanjiWordId=${kanjiWords[kanjiWordIx + 1].id}`, {
                        position: kanjiWords[kanjiWordIx + 1].position - 1
                    }).catch((err) => {
                        console.log("err: " + err);
                    }).then((response) => {
                        if (response && response.data.success === true && response.data.status === 201) {
                            fetchKanjiWords();
                        }
                    });
                }
            });

        }
    }

    const decreasePosition = async (kanjiWord) => {
        console.log(kanjiWord);
        if (kanjiWords.length >= 2 && kanjiWord.position !== 1) {
            var kanjiWordIx = kanjiWords.findIndex(kw => kw.id === kanjiWord.id);
            await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateKanjiWord?token=${token}&kanjiWordId=${kanjiWord.id}`, {
                position: kanjiWord.position - 1
            }).catch(async (err) => {
                console.log("err: " + err);
            }).then(async (response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateKanjiWord?token=${token}&kanjiWordId=${kanjiWords[kanjiWordIx - 1].id}`, {
                        position: kanjiWords[kanjiWordIx - 1].position + 1
                    }).catch((err) => {
                        console.log("err: " + err);
                    }).then((response) => {
                        if (response && response.data.success === true && response.data.status === 201) {
                            fetchKanjiWords();
                        }
                    });
                }
            });

        }
    }

    return (
        <Dialog
            sx={{ margin: "100px" }} open={dialogOpen}
            fullScreen
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    Kanji Kelimeleri
                </Typography>
            </Box>
            <DialogContent>
                {!(admin.permitted_language) &&
                    <Box sx={{ textAlign: "center" }}>
                        <Button variant='contained' color='success' sx={{ width: '25ch' }} onClick={() => {
                            setKanjiWordDialogOpen(true);
                        }} >
                            Yeni Kelime Ekle
                        </Button>
                    </Box>
                }

                <Box display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))" mt="20px">
                    {kanjiWords.map(w => <KanjiWordBox key={w.id} kanjiWord={w} removeKanjiWord={handleKanjiWordToRemove} updateKanjiWord={updateKanjiWord} increasePosition={increasePosition} decreasePosition={decreasePosition} />)}
                </Box>
            </DialogContent>

            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Kanji kelimesini silmek istediğinizden emin misiniz?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Kanji kelimesi ve ilgili tüm veriler (çeviriler, favoriler) silinecektir. Bu işlemi geri alamazsınız, dilerseniz aynı kelimeyi tekrar ekleyebilirsiniz.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Vazgeç</Button>
                    <Button onClick={() => removeKanjiWord()}>Sil</Button>
                </DialogActions>
            </Dialog>

            <KanjiWordDialog targetKanjiWord={targetKanjiWord} kanji={targetKanji} dialogOpen={kanjiWordDialogOpen} handleClose={handleKanjiWordDialogClose} handleFormSubmit={handleFormSubmit} />
        </Dialog>
    )
}

export default KanjiWordsDialog