import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../../global/Header';
import SearchIcon from '@mui/icons-material/Search';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';
import { AdminContext } from '../../../context/AdminContext';
import { tokens } from '../../../theme';
import KanjiWordBox from '../KanjiWordsDialog/KanjiWordBox';
import KanjiWordDialog from '../KanjiWordsDialog/KanjiWordDialog';


function KanjiWordsPage() {
    const colors = tokens()
    const { token } = useContext(AdminContext);
    const [searchKey, setSearchKey] = useState("");
    const [level, setLevel] = useState(0);
    const [wordCounts, setWordCounts] = useState({ "n1": 0, "n2": 0, "n3": 0, "n4": 0, "n5": 0 });
    const [words, setWords] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [targetWord, setTargetWord] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [wordToRemove, setWordToRemove] = useState(null);

    const fetchWordCounts = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getKanjiWordCounts?token=${token}&countPassives=1`)
            .catch((err) => {
                console.log("err: " + err);
                setWordCounts({ "n1": 0, "n2": 0, "n3": 0, "n4": 0, "n5": 0 });
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    if (response.data.kanjiWordCounts && response.data.kanjiWordCounts.length > 0) {
                        const getWordCount = (kanjiWordCounts, level) => {
                            var count = kanjiWordCounts.find((c) => c.level == level) || { kanji_word_count: 0 };
                            return count.kanji_word_count;
                        }
                        setWordCounts(
                            {
                                "n1": getWordCount(response.data.kanjiWordCounts, 1),
                                "n2": getWordCount(response.data.kanjiWordCounts, 2),
                                "n3": getWordCount(response.data.kanjiWordCounts, 3),
                                "n4": getWordCount(response.data.kanjiWordCounts, 4),
                                "n5": getWordCount(response.data.kanjiWordCounts, 5)
                            }
                        );
                    } else {
                        setWordCounts({ "n1": 0, "n2": 0, "n3": 0, "n4": 0, "n5": 0 });
                    }
                }
            });
    }

    const fetchWords = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getKanjiWords?token=${token}&searchKey=${searchKey}&level=${level}&startIndex=${startIndex}&limit=50`)
            .catch((err) => {
                console.log("err: " + err);
                setWords([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.kanjiWords && response.data.kanjiWords.length === 50);
                    if (startIndex === 0) {
                        setWords(response.data.kanjiWords);
                        fetchWordCounts()
                    } else {
                        setWords((prev) => [...prev, ...response.data.kanjiWords]);
                    }
                }
            });
    }

    useEffect(() => {
        fetchWords();
    }, [startIndex]);

    const handleFormSubmit = async (values) => {
        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateWordWithTranslations?token=${token}&wordId=${targetWord.id}`, {
            ...values
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                handleClose();
                if (startIndex === 0) {
                    fetchWords();
                } else {
                    setStartIndex(0);
                }
            }
        });
    }

    useEffect(() => {
        if (targetWord) {
            setDialogOpen(true);
        }
    }, [targetWord]);

    const handleClose = async () => {
        setDialogOpen(false);
        setTargetWord(null);
    }

    const updateWord = (w) => {
        console.log(w)
        setTargetWord(w);
    }

    useEffect(() => {
        if (wordToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [wordToRemove]);

    const handleRemoveDialogClose = async () => {
        setRemoveDialogOpen(false);
        setWordToRemove(null);
    }

    const handleWordToRemove = (w) => {
        if (w) {
            setWordToRemove(w);
        }
    }

    const removeWord = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteKanjiWord?token=${token}&kanjiWordId=${wordToRemove.id}&kanjiWordPositionToDecreaseOthers=${wordToRemove.position}&kanjiIdToDecreaseOthers=${wordToRemove.kanji_id}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                if (startIndex === 0) {
                    setWords(response.data.words);
                    fetchWordCounts()
                } else {
                    setWords((prev) => [...prev, ...response.data.words]);
                }
                handleRemoveDialogClose();
            }
        });
    }

    return (
        <Box sx={{ p: "75px" }}>
            {targetWord && <KanjiWordDialog targetKanjiWord={targetWord} dialogOpen={dialogOpen} handleClose={handleClose} handleFormSubmit={handleFormSubmit} />}
            <Dialog
                open={removeDialogOpen}
                keepMounted
                onClose={handleRemoveDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Kelimeyi silmek istediğinize emin misiniz?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Kelime ve ilgili tüm veriler (çeviriler vb.) silinecektir. Bu işlemi geri alamazsınız, dilerseniz aynı kelimeyi tekrar ekleyebilirsiniz.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveDialogClose}>Vazgeç</Button>
                    <Button onClick={() => removeWord()}>Sil</Button>
                </DialogActions>
            </Dialog>
            <Box display="flex" alignItems="center" gap="20px">
                <Header title="Kelimeler" subtitle="Kelimeleri bu sayfadan görebilir ve yönetebilirsiniz." />
            </Box>
            <Box display="flex" alignItems="center" gap="20px">
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel htmlFor="search-box">Ara</InputLabel>
                    <OutlinedInput
                        id="search-box"
                        type='text'
                        onChange={(e) => {
                            setSearchKey(e.target.value ? e.target.value : "");
                        }}
                        label="Ara"
                    />
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel id="filter-level">Seviye</InputLabel>
                    <Select
                        labelId="filter-level"
                        label="Seviye"
                        value={level}
                        onChange={(e) => {
                            setLevel(e.target.value ? e.target.value : 0);
                        }}
                    >
                        <MenuItem value={0}>Tümü {"(" + (Object.values(wordCounts).reduce((accumulator, currentValue) => { return accumulator + currentValue }, 0)) + ")"}</MenuItem>
                        <MenuItem value={1}>N1 {"(" + (wordCounts.n1) + ")"}</MenuItem>
                        <MenuItem value={2}>N2 {"(" + (wordCounts.n2) + ")"}</MenuItem>
                        <MenuItem value={3}>N3 {"(" + (wordCounts.n3) + ")"}</MenuItem>
                        <MenuItem value={4}>N4 {"(" + (wordCounts.n4) + ")"}</MenuItem>
                        <MenuItem value={5}>N5 {"(" + (wordCounts.n5) + ")"}</MenuItem>
                    </Select>
                </FormControl>
                <IconButton
                    aria-label="search button"
                    sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                    onClick={() => {
                        setWords([]);
                        if (startIndex === 0) {
                            fetchWords();
                        } else {
                            setStartIndex(0);
                        }
                    }}
                    edge="end"
                >
                    <SearchIcon />
                </IconButton>
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={words.length}
                    next={() => {
                        setStartIndex(words.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Tüm kanjileri görüntülüyorsunuz</b>
                        </p>
                    }
                >
                    <Box display="flex" flexWrap="wrap" gap="20px">
                        {words.map(kw =>
                            <Box key={kw.id + "-word"} maxWidth="30%">
                                <KanjiWordBox kanjiWord={kw} updateKanjiWord={updateWord} removeKanjiWord={handleWordToRemove} searchKey={searchKey} />
                            </Box>
                        )}
                    </Box>
                </InfiniteScroll>
            </Box>
        </Box>
    )
}

export default KanjiWordsPage
