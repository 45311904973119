import React, { useContext } from 'react'
import { AdminContext } from '../context/AdminContext';

function CopyCheckImage(props) {

    const { admin } = useContext(AdminContext);

    return (
        <img
            alt=''
            {...props}
            onMouseDown={(e) => {
                if (admin.copy_perm === 0) {
                    if (e.button === 2) {
                        e.preventDefault();
                        alert("Kopyalanamaz.");
                    }
                }
            }}
            onDragStart={(e) => {
                if (admin.copy_perm === 0) {
                    e.preventDefault();
                    alert("Kopyalanamaz.");
                }
            }}
        />
    )
}

export default CopyCheckImage