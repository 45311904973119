import { Box, Button } from '@mui/material'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import Header from '../../global/Header';
import { AdminContext } from '../../context/AdminContext';
import InfiniteScroll from 'react-infinite-scroll-component';
import AlertDialogSlide from '../../components/AlertDialogSlide';
import ContactMessageBox from './ContactMessageBox';

function ContactMessagesPage() {
    const { token } = useContext(AdminContext);
    const [contactMessages, setContactMessages] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [startIndex, setStartIndex] = useState(0);
    const [contactMessageToRemove, setContactMessageToRemove] = useState(null);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);

    useEffect(() => {
        fetchContactMessages();
    }, [startIndex]);

    useEffect(() => {
        if (contactMessageToRemove) {
            setRemoveDialogOpen(true);
        }
    }, [contactMessageToRemove]);

    const fetchContactMessages = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getContactMessages?token=${token}&startIndex=${startIndex}&limit=20`)
            .catch((err) => {
                console.log("err: " + err);
                setContactMessages([]);
                setHasMore(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setHasMore(response.data.contactMessages && response.data.contactMessages.length === 20);
                    if (startIndex === 0) {
                        setContactMessages(response.data.contactMessages);
                    } else {
                        setContactMessages((prev) => [...prev, ...response.data.contactMessages]);
                    }
                }
            });
    }

    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
    }
    const clickPositiveAnswer = () => {
        removeContactMessage(contactMessageToRemove);
    }
    const clickNegativeAnswer = () => {
        handleRemoveDialogClose();
    }

    const removeContactMessage = (contactMessage) => {
        axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteContactMessage?contactMessageId=${contactMessage.id}&token=${token}`)
            .catch((err) => {
                console.log("err: " + err);
                setContactMessageToRemove(null);
                setRemoveDialogOpen(false);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    var ix = contactMessages.findIndex(({ id }) => id === contactMessage.id);
                    contactMessages.splice(ix, 1);
                    setContactMessages(Array.from(contactMessages));
                    setContactMessageToRemove(null);
                    setRemoveDialogOpen(false);
                }
            });
    }

    return (
        <Box sx={{ p: "75px" }}>
            {
                contactMessageToRemove &&
                <AlertDialogSlide
                    open={removeDialogOpen} handleClose={() => {
                        handleRemoveDialogClose();
                        setContactMessageToRemove(null)
                    }}
                    clickPositiveAnswer={clickPositiveAnswer} clickNegativeAnswer={clickNegativeAnswer}
                    positiveAnswer={"Sil"} negativeAnswer={"İptal"}
                    title={"Bu mesajı silmek istediğinize emin misiniz?"}
                    description={"İlgili veri silinecektir ve bu işlem geri alınamaz."}
                />
            }

            <Box display="flex" alignItems="center" gap="30px">
                <Header title="İletişim Mesajları" subtitle="Bu sayfadan kullanıcıların gönderdiği iletişim mesajlarını görüntüleyebilirsiniz." />
            </Box>
            <Box mt="30px">
                <InfiniteScroll
                    dataLength={contactMessages.length}
                    next={() => {
                        setStartIndex(contactMessages.length);
                    }}
                    hasMore={hasMore}
                    loader={<h4>Yükleniyor...</h4>}
                    endMessage={
                        <p style={{ textAlign: 'center' }}>
                            <b>Tüm iletişim mesajlarını görüntülüyorsunuz.</b>
                        </p>
                    }
                >
                    <Box m="10px 0 0 0" display="grid" gap="15px" gridTemplateColumns="repeat(3, minmax(0, 1fr))">
                        {contactMessages.map(contactMessage =>
                            <ContactMessageBox
                                key={contactMessage.id}
                                contactMessage={contactMessage}
                                onDeleteButtonClick={() => setContactMessageToRemove(contactMessage)}
                            />
                        )}
                    </Box>
                </InfiniteScroll>
            </Box>

        </Box>
    )
}

export default ContactMessagesPage