import { Box, IconButton, Menu, MenuItem, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import axios from 'axios';
import { AdminContext } from '../../../context/AdminContext';
import { tokens } from '../../../theme';
import CopyCheckTypography from '../../../components/CopyCheckTypography';
import CopyCheckImage from '../../../components/CopyCheckImage';

function KanjiBox({ kanji, updateKanji, removeKanji, openKanjiWords, searchKey }) {
    const { token, admin } = useContext(AdminContext);
    const colors = tokens();
    const [anchorEl, setAnchorEl] = useState(null);
    const [translations, setTranslations] = useState([]);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        fetchTranslations(kanji.id);
    }, [kanji])

    const fetchTranslations = () => {
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getKanjiTranslations?token=${token}&kanjiId=${kanji.id}`)
            .catch((err) => {
                console.log("err: " + err);
                setTranslations([]);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setTranslations(response.data.translations);
                }
            });
    }

    return (
        <Box width="300px" sx={kanji.active ? {} : { opacity: 0.5 }}>
            <Menu
                id="menu"
                aria-labelledby="open-menu-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose();
                    openKanjiWords(kanji);
                }}>
                    Kelimeleri Gör
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose();
                    updateKanji({ ...kanji, translations: translations });
                }}>
                    Düzenle
                </MenuItem>

                {admin.content_deletion_perm === 1 &&
                    <MenuItem onClick={() => {
                        handleClose()
                        removeKanji(kanji);
                    }}>
                        Sil
                    </MenuItem>
                }
            </Menu>
            <Box position="relative" bgcolor={colors.grey[900]} p="20px" borderRadius="10px">
                <Box position="absolute" top={15} right={15}>
                    <IconButton
                        id="open-menu-button"
                        aria-controls={open ? 'menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}>
                        <MoreVertIcon sx={{ scale: "1.2" }} />
                    </IconButton>
                </Box>
                <Box display="flex" alignItems="center" gap="20px">
                    <CopyCheckImage src={kanji.kanji_image_link} alt='kanji' height="50px" width="50px" />
                    <CopyCheckImage src={kanji.stroke_order_gif_link} alt='kanji' height="50px" width="50px" />
                    <CopyCheckTypography variant='h3' fontWeight="600" color={searchKey && kanji.kanji.startsWith(searchKey) ? "orange" : null} >
                        {kanji.kanji}
                    </CopyCheckTypography>
                </Box>
                <Box mt="10px" p="10px" display="flex" justifyContent="space-between" textAlign="center">
                    <Box>
                        <CopyCheckTypography variant='h6' fontWeight={600} sx={{ textDecoration: "underline" }}>Onyomi</CopyCheckTypography>
                        <CopyCheckTypography variant='h6' >{kanji.onyomi}</CopyCheckTypography>
                    </Box>
                    <Box>
                        <CopyCheckTypography variant='h6' fontWeight={600} sx={{ textDecoration: "underline" }}>Kunyomi</CopyCheckTypography>
                        <CopyCheckTypography variant='h6' >{kanji.kunyomi}</CopyCheckTypography>
                    </Box>
                    <Box>
                        <CopyCheckTypography variant='h6' fontWeight={600} sx={{ textDecoration: "underline" }}>N Seviyesi</CopyCheckTypography>
                        <CopyCheckTypography variant='h6' >{kanji.level}</CopyCheckTypography>
                    </Box>
                </Box>
                <Box component="hr" my="10px" border={"1px solid " + colors.grey[500]} />
                <Box>
                </Box>

                <Box mt="15px" display="grid" gap="10px" gridTemplateColumns="repeat(2, minmax(0, 1fr))">
                    <CopyCheckTypography sx={{ textDecoration: "underline", gridColumn: "span 2", textAlign: "center" }} variant='h4'><b>Çeviriler</b></CopyCheckTypography>
                    {(admin.permitted_language ? translations.filter((tra) => tra.language === "en" || tra.language === admin.permitted_language) : translations).map(tra =>
                        <Box key={"tra-" + tra.language} sx={{ gridColumn: "span 1" }}>
                            <CopyCheckTypography variant='h5' fontWeight={searchKey && tra.translation.startsWith(searchKey) ? "600" : "400"} color={searchKey && tra.translation.startsWith(searchKey) ? "orange" : null}>{tra.language.toUpperCase() + ": " + tra.translation}</CopyCheckTypography>
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    )
}

export default KanjiBox