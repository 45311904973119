import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material'
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid'
import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import { tokens } from '../../theme';
import Header from '../../global/Header';
import UserDialog from './UserDialog';
import { AdminContext } from '../../context/AdminContext';
import { Remove } from '@mui/icons-material';
import countries from './countries.json';

function UsersPage() {
    const { token } = useContext(AdminContext);
    const colors = tokens();
    const [isLoading, setLoading] = useState(true);
    const [searchKey, setSearchKey] = useState("");
    const [searchId, setSearchId] = useState(null);
    const [country, setCountry] = useState(null);
    const [language, setLanguage] = useState(null);
    const [orderModel, setOrderModel] = useState(null);
    const [rowCount, setRowCount] = useState(0);
    const [users, setUsers] = useState([]);
    const [targetUser, setTargetUser] = useState(null);
    const [userToRemove, setUserToRemove] = useState(null);
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    const [removeUserDialogOpen, setRemoveUserDialogOpen] = useState(false);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 10
    });

    const updateUser = async (userId, user) => {
        var userIx = users.findIndex(u => u.id === userId);
        if (user.photo) {
            await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadUserProfilePhoto?token=${token}`, {
                profilePhoto: user.photo
            }, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .catch((err) => {
                    console.log("err: " + err);
                })
                .then((response) => {
                    if (response && response.data.success === true && response.data.status === 201) {
                        delete user.photo;
                        delete user.profile_photo_link;
                        user.profile_photo_path = response.data.photoPath;
                    }
                });
        }

        var userToUpdate = { ...user };
        delete userToUpdate.photo;
        delete userToUpdate.profile_photo_link;

        await axios.patch(`${process.env.REACT_APP_API_URL}webAdmin/updateUser?userId=${userId}&token=${token}`, {
            user: userToUpdate
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                    if (userIx !== null && userIx !== undefined && userIx !== -1) {
                        setUsers(prev => {
                            var newUsers = [...prev];
                            newUsers[userIx] = { ...newUsers[userIx], ...userToUpdate };
                            return newUsers;
                        })
                    }
                    if (editUserDialogOpen) {
                        setEditUserDialogOpen(false);
                    }
                }
            });
    }

    const columns = [
        {
            field: 'id', headerName: 'ID', flex: 0.2
        },
        {
            field: 'profile_photo_link', headerName: "Profil Fotoğrafı", sortable: false,
            flex: 0.1, renderCell: (params) => <img src={params.value} alt='profile-pic' style={{ height: "30px", width: "30px", margin: "auto" }} />
        },
        {
            field: 'name',
            headerName: 'AD'
        }, {
            field: 'surname',
            headerName: 'SOYAD'
        }, {
            field: 'email',
            headerName: 'E-POSTA',
            flex: 1
        }, {
            field: 'level',
            headerName: 'N SEVİYESİ'
        }, {
            field: 'heart',
            headerName: 'EKSTRA CAN'
        }, {
            field: 'user_agreement',
            headerName: 'SÖZLEŞME ONAYI',
            type: "boolean"
        }, {
            field: 'is_premium',
            headerName: 'PREMIUM',
            type: "boolean"
        }, {
            field: 'premium_type',
            headerName: 'ÜYELİK TİPİ',
            renderCell: ({ row: { premium_type } }) => {
                switch (premium_type) {
                    case "free_trial":
                        return "Deneme";
                    case "monthly":
                        return "Aylık";
                    case "sixmonths":
                        return "6 Aylık";
                    case "annual":
                        return "Yıllık";
                    default:
                        return "-";
                }
            }
        }, {
            field: 'gender',
            headerName: 'CİNSİYET',
            renderCell: ({ row: { gender } }) => {
                switch (gender) {
                    case 0:
                        return "Erkek";
                    case 1:
                        return "Kadın";
                    case 2:
                        return "Diğer";
                    default:
                        return "-";
                }
            }
        }, {
            field: 'country',
            headerName: 'ÜLKE',
            renderCell: ({ row: { country } }) => {
                if (country) {
                    return country;
                } else {
                    return "-";
                }
            }
        }, {
            field: 'language',
            headerName: 'DİL',
            renderCell: ({ row: { language } }) => {
                switch (language) {
                    case "de":
                        return "Almanca";
                    case "en":
                        return "İngilizce";
                    case "es":
                        return "İspanyolca";
                    case "fr":
                        return "Fransızca";
                    case "id":
                        return "Endonezce";
                    case "it":
                        return "İtalyanca";
                    case "ko":
                        return "Korece";
                    case "tr":
                        return "Türkçe";
                    case "zh":
                        return "Çince";
                    default:
                        return "Diğer";
                }
            }
        }, {
            field: 'birth_date',
            headerName: 'DOĞUM TARİHİ',
            renderCell: ({ row: { birth_date } }) => {
                if (birth_date) {
                    return (new Date(birth_date)).toLocaleDateString();
                } else {
                    return "-";
                }
            }
        }, {
            field: "created_date", headerName: "KAYIT TARİHİ",
            renderCell: ({ row: { created_date } }) => {
                return new Date(created_date).toLocaleDateString()
            }
        },
        {
            field: 'actions',
            type: 'actions',
            sortable: false,
            width: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<PersonIcon />}
                    label="Kullanıcıyı Düzenle"
                    onClick={() => setTargetUser(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<Remove />}
                    label="Kullanıcıyı Sil"
                    onClick={() => setUserToRemove(params.row)}
                    showInMenu
                />
            ],
        }
    ];

    useEffect(() => {
        setLoading(true);
        axios.get(`${process.env.REACT_APP_API_URL}webAdmin/getUsers?token=${token}&startIndex=${paginationModel.pageSize * paginationModel.page}&limit=${paginationModel.pageSize}${searchKey ? `&searchKey=${searchKey}` : ""}${searchId ? `&searchId=${searchId}` : ""}${language ? `&language=${language}` : ""}${country ? `&country=${country}` : ""}${orderModel ? `&orderField=${orderModel.field}&orderType=${orderModel.sort}` : ""}`)
            .catch((err) => {
                setUsers([]);
                setRowCount(0);
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 200) {
                    setUsers(response.data.users);
                    setRowCount((prevRowCount) => response.data.rowCount !== undefined ? response.data.rowCount : prevRowCount);
                }
                setLoading(false);
            });
    }, [paginationModel, language, country]);

    useEffect(() => {
        if (targetUser) {
            setEditUserDialogOpen(true);
        }
    }, [targetUser]);

    const handleEditUserDialogClose = () => {
        setTargetUser(null);
        setEditUserDialogOpen(false);
    }

    useEffect(() => {
        if (userToRemove) {
            setRemoveUserDialogOpen(true);
        }
    }, [userToRemove]);

    const handleRemoveUserDialogClose = () => {
        setUserToRemove(null);
        setRemoveUserDialogOpen(false);
    }

    const removeUser = async () => {
        await axios.delete(`${process.env.REACT_APP_API_URL}webAdmin/deleteUserWithoutApple?id=${userToRemove.id}&token=${token}`).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                users.splice(users.findIndex(u => u.id === userToRemove.id), 1);
                handleRemoveUserDialogClose();
            }
        });
    }

    return (
        <Box sx={{ p: "75px" }}>
            <UserDialog dialogOpen={editUserDialogOpen} handleClose={handleEditUserDialogClose} targetUser={targetUser} handleFormSubmit={(values) => updateUser(targetUser.id, values)} />

            <Dialog
                open={removeUserDialogOpen}
                keepMounted
                onClose={handleRemoveUserDialogClose}
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle>Kullanıcıyı silmek istediğinize emin misiniz?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Kullanıcı hesabı ve ilgili tüm veriler silinecektir. Bu işlemi geri alamazsınız.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRemoveUserDialogClose}>Vazgeç</Button>
                    <Button onClick={() => removeUser()}>Sil</Button>
                </DialogActions>
            </Dialog>

            <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center" gap="20px">
                    <Header title="Kullanıcılar" subtitle="Bu sayfadan kullanıcıları görüntüleyebilir ve düzenleyebilirsiniz." />
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Ara</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='text'
                            onChange={(e) => {
                                setSearchKey(e.target.value ? e.target.value : "");
                            }}
                            label="Ara"
                        />
                    </FormControl>
                    <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                        <InputLabel htmlFor="search-box">Ara(Id)</InputLabel>
                        <OutlinedInput
                            id="search-box"
                            type='number'
                            onChange={(e) => {
                                setSearchId(e.target.value ? e.target.value : "");
                            }}
                            label="Ara(Id)"
                        />
                    </FormControl>

                    <IconButton
                        aria-label="search button"
                        sx={{ background: colors.grey[900], borderRadius: "10px", padding: "12px" }}
                        onClick={() => { setPaginationModel(prev => { return { ...prev, page: 0 } }) }}
                        edge="end"
                    >
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <Box display="flex" alignItems="center" gap="20px">
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel id="filter-language">Dil</InputLabel>
                    <Select
                        labelId="filter-language"
                        label="Dil"
                        value={language}
                        onChange={(e) => {
                            setLanguage(e.target.value ? e.target.value : null);
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: "33%",
                                    width: '20ch',
                                },
                            },
                        }}
                    >
                        <MenuItem value={null}>Tümü</MenuItem>
                        <MenuItem value={"tr"}>Türkçe</MenuItem>
                        <MenuItem value={"en"}>İngilizce</MenuItem>
                        <MenuItem value={"de"}>Almanca</MenuItem>
                        <MenuItem value={"it"}>İtalyanca</MenuItem>
                        <MenuItem value={"fr"}>Fransızca</MenuItem>
                        <MenuItem value={"es"}>İspanyolca</MenuItem>
                        <MenuItem value={"ko"}>Korece</MenuItem>
                        <MenuItem value={"zh"}>Çince</MenuItem>
                        <MenuItem value={"id"}>Endonezce</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel id="filter-country">Ülke</InputLabel>
                    <Select
                        labelId="filter-country"
                        label="Ülke"
                        value={country}
                        onChange={(e) => {
                            setCountry(e.target.value ? e.target.value : null);
                        }}
                        MenuProps={{
                            PaperProps: {
                                style: {
                                    maxHeight: "33%",
                                    width: '20ch',
                                },
                            },
                        }}
                    >
                        <MenuItem value={null}>Tümü</MenuItem>
                        {countries.map((country) => {
                            return <MenuItem key={country.code} value={country.name}>{country.name}</MenuItem>
                        })
                        }
                    </Select>
                </FormControl>
            </Box>
            <DataGrid
                rows={users}
                columns={columns}
                rowCount={rowCount}
                loading={isLoading}
                pageSizeOptions={[10, 25, 50, 75, 100]}
                paginationModel={paginationModel}
                paginationMode="server"
                onPaginationModelChange={setPaginationModel}
                sortingMode='server'
                onSortModelChange={(model) => {
                    setOrderModel((prev) => model && model.length > 0 ? model[0] : null);
                    setPaginationModel(prev => { return { ...prev, page: 0 } });
                }}
                disableColumnFilter
                sx={{
                    ".MuiDataGrid-columnHeaders": {
                        background: colors.grey[900]
                    },
                    marginTop: "20px"
                }}
            />
        </Box>
    )
}

export default UsersPage