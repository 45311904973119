import React, { useEffect, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';

function AdminDialog({ targetAdmin, dialogOpen, handleClose, handleFormSubmit }) {

    var initialValues = {
        username: targetAdmin?.username ? targetAdmin?.username : "",
        ...(!targetAdmin ? { password: "" } : {}),
        users_perm: targetAdmin?.users_perm !== undefined && targetAdmin?.users_perm !== null ? targetAdmin?.users_perm : "",
        contents_perm: targetAdmin?.contents_perm !== undefined && targetAdmin?.contents_perm !== null ? targetAdmin?.contents_perm : "",
        notifications_perm: targetAdmin?.notifications_perm !== undefined && targetAdmin?.notifications_perm !== null ? targetAdmin?.notifications_perm : "",
        faqs_perm: targetAdmin?.faqs_perm !== undefined && targetAdmin?.faqs_perm !== null ? targetAdmin?.faqs_perm : "",
        contact_messages_perm: targetAdmin?.contact_messages_perm !== undefined && targetAdmin?.contact_messages_perm !== null ? targetAdmin?.contact_messages_perm : "",
        copy_perm: targetAdmin?.copy_perm !== undefined && targetAdmin?.copy_perm !== null ? targetAdmin?.copy_perm : "",
        content_deletion_perm: targetAdmin?.content_deletion_perm !== undefined && targetAdmin?.content_deletion_perm !== null ? targetAdmin?.content_deletion_perm : "",
        permitted_language: targetAdmin?.permitted_language !== undefined && targetAdmin?.permitted_language !== null ? targetAdmin?.permitted_language : "",
        admins_perm: targetAdmin?.admins_perm !== undefined && targetAdmin?.admins_perm !== null ? targetAdmin?.admins_perm : "",
    }

    var validationObject = {
        username: yup.string().required("zorunlu"),
        ...(!targetAdmin ? { password: yup.string().required("zorunlu") } : {}),
        users_perm: yup.number().min(0, "Lütfen Seçiniz.").required("zorunlu"),
        contents_perm: yup.number().min(0, "Lütfen Seçiniz.").required("zorunlu"),
        notifications_perm: yup.number().min(0, "Lütfen Seçiniz.").required("zorunlu"),
        faqs_perm: yup.number().min(0, "Lütfen Seçiniz.").required("zorunlu"),
        contact_messages_perm: yup.number().min(0, "Lütfen Seçiniz.").required("zorunlu"),
        copy_perm: yup.number().min(0, "Lütfen Seçiniz.").required("zorunlu"),
        content_deletion_perm: yup.number().min(0, "Lütfen Seçiniz.").required("zorunlu"),
        permitted_language: yup.string().nullable(),
        admins_perm: yup.number().min(0, "Lütfen Seçiniz.").required("zorunlu")
    }

    const adminSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                {targetAdmin &&
                    <Typography variant='h3'>
                        Yöneticiyi Düzenle
                    </Typography>
                }
                {!targetAdmin &&
                    <Typography variant='h3'>
                        Yeni Yönetici
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={adminSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="25px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kullanıcı Adı"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"username"}
                                        value={values.username}
                                        error={!!touched.username && !!errors.username}
                                        helperText={touched.username && errors.username}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                    {!targetAdmin &&
                                        <TextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Şifre"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"password"}
                                            value={values.password}
                                            error={!!touched.password && !!errors.password}
                                            helperText={touched.password && errors.password}
                                            sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                        />}

                                    <Typography gridColumn="span 2" variant='h4' mt="20px">İzinler</Typography>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label1">Kullanıcılar Sayfası İzni</InputLabel>
                                        <Select
                                            labelId="filter-label1"
                                            label="Kullanıcılar Sayfası İzni"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"users_perm"}
                                            value={values.users_perm}
                                            error={!!touched.users_perm && !!errors.users_perm}
                                        >
                                            <MenuItem value={0}>Yok</MenuItem>
                                            <MenuItem value={1}>Var</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label2">Kanji Sayfası İzni</InputLabel>
                                        <Select
                                            labelId="filter-label2"
                                            label="Kanji Sayfası İzni"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"contents_perm"}
                                            value={values.contents_perm}
                                            error={!!touched.contents_perm && !!errors.contents_perm}
                                        >
                                            <MenuItem value={0}>Yok</MenuItem>
                                            <MenuItem value={1}>Var</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label3">Bildirim Gönderme İzni</InputLabel>
                                        <Select
                                            labelId="filter-label3"
                                            label="Bildirim Gönderme İzni"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"notifications_perm"}
                                            value={values.notifications_perm}
                                            error={!!touched.notifications_perm && !!errors.notifications_perm}
                                        >
                                            <MenuItem value={0}>Yok</MenuItem>
                                            <MenuItem value={1}>Var</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label4">SSS Sayfası İzni</InputLabel>
                                        <Select
                                            labelId="filter-label4"
                                            label="SSS Sayfası İzni"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"faqs_perm"}
                                            value={values.faqs_perm}
                                            error={!!touched.faqs_perm && !!errors.faqs_perm}
                                        >
                                            <MenuItem value={0}>Yok</MenuItem>
                                            <MenuItem value={1}>Var</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label5">Mesajlar Sayfası İzni</InputLabel>
                                        <Select
                                            labelId="filter-label5"
                                            label="Mesajlar Sayfası İzni"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"contact_messages_perm"}
                                            value={values.contact_messages_perm}
                                            error={!!touched.contact_messages_perm && !!errors.contact_messages_perm}
                                        >
                                            <MenuItem value={0}>Yok</MenuItem>
                                            <MenuItem value={1}>Var</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label6">Kopyalama İzni</InputLabel>
                                        <Select
                                            labelId="filter-label6"
                                            label="Kopyala İzni"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"copy_perm"}
                                            value={values.copy_perm}
                                            error={!!touched.copy_perm && !!errors.copy_perm}
                                        >
                                            <MenuItem value={0}>Yok</MenuItem>
                                            <MenuItem value={1}>Var</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label6">İçerik Silme İzni</InputLabel>
                                        <Select
                                            labelId="filter-label6"
                                            label="İçerik Silme İzni"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"content_deletion_perm"}
                                            value={values.content_deletion_perm}
                                            error={!!touched.content_deletion_perm && !!errors.content_deletion_perm}
                                        >
                                            <MenuItem value={0}>Yok</MenuItem>
                                            <MenuItem value={1}>Var</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span " }}>
                                        <InputLabel id="filter-label8">İzin Verilen Dil</InputLabel>
                                        <Select
                                            labelId="filter-label8"
                                            label="İzin Verilen Dil"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"permitted_language"}
                                            value={values.permitted_language}
                                            error={!!touched.permitted_language && !!errors.permitted_language}
                                        >
                                            <MenuItem value={""}>Tümü</MenuItem>
                                            <MenuItem value={"tr"}>Türkçe</MenuItem>
                                            <MenuItem value={"en"}>İngilizce</MenuItem>
                                            <MenuItem value={"de"}>Almanca</MenuItem>
                                            <MenuItem value={"it"}>İtalyanca</MenuItem>
                                            <MenuItem value={"fr"}>Fransızca</MenuItem>
                                            <MenuItem value={"es"}>İspanyolca</MenuItem>
                                            <MenuItem value={"ko"}>Korece</MenuItem>
                                            <MenuItem value={"zh"}>Çince</MenuItem>
                                            <MenuItem value={"id"}>Endonezce</MenuItem>
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 1" }}>
                                        <InputLabel id="filter-label7">Yöneticiler Sayfası İzni</InputLabel>
                                        <Select
                                            labelId="filter-label7"
                                            label="Yöneticiler Sayfası İzni"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"admins_perm"}
                                            value={values.admins_perm}
                                            error={!!touched.admins_perm && !!errors.admins_perm}
                                        >
                                            <MenuItem value={0}>Yok</MenuItem>
                                            <MenuItem value={1}>Var</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default AdminDialog