import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { tokens } from './../../../theme';

function ContactMessageBox({ contactMessage, onDeleteButtonClick }) {
    const colors = tokens();
    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ gridColumn: "span 1", border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box padding="10px" display="flex" justifyContent="space-between" alignItems="center" >
                <Box display="flex" gap="10px" alignItems="center">
                    <Box component="img" src={contactMessage.user_profile_photo_link} alt={contactMessage.user_name} sx={{ objectFit: "cover", width: "50px", height: "50px", borderRadius: "25px" }} />
                    <Box>
                        <Typography variant="h3">{contactMessage.user_name} {contactMessage.user_surname}</Typography>
                        <Typography variant="h6">{(new Date(contactMessage.created_date)).toLocaleDateString()} {(new Date(contactMessage.created_date)).toLocaleTimeString()}</Typography>
                    </Box>
                </Box>
            </Box>
            <Box component="hr" height="1px" width="100%" sx={{ background: "black", border: "0px", margin: "0px" }} />
            <Box padding="10px">
                <Typography variant='h5' mt="10px">{contactMessage.message}</Typography>
            </Box>
            <Box display="flex" gap="10px" padding="10px" justifyContent="end">
                <Button variant="contained" color="error" sx={{ width: "35%", borderRadius: "12px" }} onClick={() => onDeleteButtonClick()}>Mesajı Sil</Button>
            </Box>
        </Box>
    )
}

export default ContactMessageBox