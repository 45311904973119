import React from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';

function UserDialog({ targetUser, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    var initialValues = {
        name: targetUser?.name ? targetUser?.name : "",
        surname: targetUser?.surname ? targetUser?.surname : "",
        heart: targetUser?.heart !== undefined && targetUser?.heart !== null ? targetUser?.heart : 0,
        level: targetUser?.level !== undefined && targetUser?.level !== null ? targetUser?.level : 5,
        photo: null,
        profile_photo_link: targetUser?.profile_photo_link ? targetUser?.profile_photo_link : ""
    };

    var validationObject = {
        name: yup.string().required("zorunlu"),
        surname: yup.string().required("zorunlu"),
        heart: yup.number().min(0, "0'dan küçük olamaz.").required("zorunlu"),
        level: yup.number().min(1, "1'den küçük olamaz.").max(5, "5'ten büyük olamaz.").required("zorunlu"),
        photo: yup.mixed().nullable().when('profile_photo_link', {
            is: (profile_photo_link) => !profile_photo_link || profile_photo_link === "",
            then: yup.string().nonNullable("").required('zorunlu')
        }),
        profile_photo_link: yup.string()
    }

    const userSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">

                <Typography variant='h3'>
                    Kullanıcıyı Düzenle
                </Typography>

            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={userSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box textAlign="center">
                                    <Typography variant="h6">Profil Fotoğrafı</Typography>
                                    <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                        {(values.photo || (values.profile_photo_link && values.profile_photo_link !== "")) && <img alt='user-profile' width="100px" src={values.photo ? URL.createObjectURL(values.photo) : values.profile_photo_link} />}
                                        <input id="photo" name="photo" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("photo", event.target.files[0]); }} hidden />
                                    </Button>
                                </Box>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(2, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="İsim"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"name"}
                                        value={values.name}
                                        error={!!touched.name && !!errors.name}
                                        helperText={touched.name && errors.name}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Soyisim"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"surname"}
                                        value={values.surname}
                                        error={!!touched.surname && !!errors.surname}
                                        helperText={touched.surname && errors.surname}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="number"
                                        label="Can"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"heart"}
                                        value={values.heart}
                                        error={!!touched.heart && !!errors.heart}
                                        helperText={touched.heart && errors.heart}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="N Seviyesi"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"level"}
                                        value={values.level}
                                        error={!!touched.level && !!errors.level}
                                        helperText={touched.level && errors.level}
                                        sx={{ gridColumn: "span 1", marginTop: "20px" }}
                                    />
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained" onClick={() => console.log(errors)}>
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog >
    )
}

export default UserDialog