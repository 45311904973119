import { Typography } from '@mui/material'
import React, { useContext } from 'react'
import { AdminContext } from '../context/AdminContext';

const userSelectStyles = { userSelect: "none", msUserSelect: "none", WebkitUserSelect: "none" };

function CopyCheckTypography(props) {
    const { admin } = useContext(AdminContext);
    return (
        <Typography {...props} sx={props?.sx ? { ...(props.sx), ...(admin.copy_perm === 1 ? {} : userSelectStyles) } : admin.copy_perm === 1 ? {} : userSelectStyles}>
            {props.children}
        </Typography>
    )
}

export default CopyCheckTypography