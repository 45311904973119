import React, { useContext } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Checkbox, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { tokens } from '../../theme';
import axios from 'axios';
import { AdminContext } from '../../context/AdminContext';

function NotificationDialog({ dialogOpen, handleClose }) {
    const colors = tokens();
    const { token } = useContext(AdminContext);

    var initialValues = {
        message_tr: "",
        send_tr: true,

        message_en: "",
        send_en: true,

        message_de: "",
        send_de: true,

        message_it: "",
        send_it: true,

        message_fr: "",
        send_fr: true,

        message_es: "",
        send_es: true,

        message_ko: "",
        send_ko: true,

        message_zh: "",
        send_zh: true,

        message_id: "",
        send_id: true,

    }

    var validationObject = {
        message_tr: yup.string().when("send_tr", {
            is: (send_tr) => send_tr === true,
            then: () => yup.string().required("zorunlu")
        }),
        send_tr: yup.boolean(),

        message_en: yup.string().when("send_en", {
            is: (send_en) => send_en === true,
            then: () => yup.string().required("zorunlu")
        }),
        send_en: yup.boolean(),

        message_de: yup.string().when("send_de", {
            is: (send_de) => send_de === true,
            then: () => yup.string().required("zorunlu")
        }),
        send_de: yup.boolean(),

        message_it: yup.string().when("send_it", {
            is: (send_it) => send_it === true,
            then: () => yup.string().required("zorunlu")
        }),
        send_it: yup.boolean(),

        message_fr: yup.string().when("send_fr", {
            is: (send_fr) => send_fr === true,
            then: () => yup.string().required("zorunlu")
        }),
        send_fr: yup.boolean(),

        message_es: yup.string().when("send_es", {
            is: (send_es) => send_es === true,
            then: () => yup.string().required("zorunlu")
        }),
        send_es: yup.boolean(),

        message_ko: yup.string().when("send_ko", {
            is: (send_ko) => send_ko === true,
            then: () => yup.string().required("zorunlu")
        }),
        send_ko: yup.boolean(),

        message_zh: yup.string().when("send_zh", {
            is: (send_zh) => send_zh === true,
            then: () => yup.string().required("zorunlu")
        }),
        send_zh: yup.boolean(),

        message_id: yup.string().when("send_id", {
            is: (send_id) => send_id === true,
            then: () => yup.string().required("zorunlu")
        }),
        send_id: yup.boolean()
    }

    const notificationSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        if (values.send_tr && values.message_tr !== "") {
            await sendNotification(values.message_tr, "tr", () => {
            });
        }
        if (values.send_en && values.message_en !== "") {
            await sendNotification(values.message_en, "en", () => {
            });
        }
        if (values.send_de && values.message_de !== "") {
            await sendNotification(values.message_de, "de", () => {
            });
        }
        if (values.send_it && values.message_it !== "") {
            await sendNotification(values.message_it, "it", () => {
            });
        }
        if (values.send_fr && values.message_fr !== "") {
            await sendNotification(values.message_fr, "fr", () => {
            });
        }
        if (values.send_es && values.message_es !== "") {
            await sendNotification(values.message_es, "es", () => {
            });
        }
        if (values.send_ko && values.message_ko !== "") {
            await sendNotification(values.message_ko, "ko", () => {
            });
        }
        if (values.send_zh && values.message_zh !== "") {
            await sendNotification(values.message_zh, "zh", () => {
            });
        }
        if (values.send_id && values.message_id !== "") {
            await sendNotification(values.message_id, "id", () => {
            });
        }

        handleClose();
    }

    const sendNotification = async (message, language, onSuccess) => {
        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/sendPushNotificationToTopic?token=${token}&topic=${language}`, {
            message: message
        }).catch((err) => {
            console.log("err: " + err);
        }).then((response) => {
            if (response && response.data.success === true && response.data.status === 201) {
                onSuccess();
            }
        });
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" mx="150px" textAlign="center">
                <Typography variant='h3'>
                    Bildirim
                </Typography>
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={notificationSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mb="25px">
                                    <Box sx={{ gridColumn: "span 1", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                        <Checkbox
                                            name={"send_tr"}
                                            checked={values.send_tr}
                                            onChange={(e) => setFieldValue("send_tr", e.target.checked)}
                                        />
                                        <Typography variant='h5'>Türkçe</Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj(Türkçe)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        disabled={!values.send_tr}
                                        name={"message_tr"}
                                        value={values.message_tr}
                                        error={!!touched.message_tr && !!errors.message_tr}
                                        helperText={touched.message_tr && errors.message_tr}
                                        sx={{ gridColumn: "span 4" }}
                                    />

                                    <Box sx={{ gridColumn: "span 1", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                        <Checkbox
                                            name={"send_en"}
                                            checked={values.send_en}
                                            onChange={(e) => setFieldValue("send_en", e.target.checked)}
                                        />
                                        <Typography variant='h5'>İngilizce</Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj(İngilizce)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        disabled={!values.send_en}
                                        name={"message_en"}
                                        value={values.message_en}
                                        error={!!touched.message_en && !!errors.message_en}
                                        helperText={touched.message_en && errors.message_en}
                                        sx={{ gridColumn: "span 4" }}
                                    />

                                    <Box sx={{ gridColumn: "span 1", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                        <Checkbox
                                            name={"send_de"}
                                            checked={values.send_de}
                                            onChange={(e) => setFieldValue("send_de", e.target.checked)}
                                        />
                                        <Typography variant='h5'>Almanca</Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj(Almanca)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        disabled={!values.send_de}
                                        name={"message_de"}
                                        value={values.message_de}
                                        error={!!touched.message_de && !!errors.message_de}
                                        helperText={touched.message_de && errors.message_de}
                                        sx={{ gridColumn: "span 4" }}
                                    />

                                    <Box sx={{ gridColumn: "span 1", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                        <Checkbox
                                            name={"send_it"}
                                            checked={values.send_it}
                                            onChange={(e) => setFieldValue("send_it", e.target.checked)}
                                        />
                                        <Typography variant='h5'>İtalyanca</Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj(İtalyanca)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        disabled={!values.send_it}
                                        name={"message_it"}
                                        value={values.message_it}
                                        error={!!touched.message_it && !!errors.message_it}
                                        helperText={touched.message_it && errors.message_it}
                                        sx={{ gridColumn: "span 4" }}
                                    />

                                    <Box sx={{ gridColumn: "span 1", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                        <Checkbox
                                            name={"send_fr"}
                                            checked={values.send_fr}
                                            onChange={(e) => setFieldValue("send_fr", e.target.checked)}
                                        />
                                        <Typography variant='h5'>Fransızca</Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj(Fransızca)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        disabled={!values.send_fr}
                                        name={"message_fr"}
                                        value={values.message_fr}
                                        error={!!touched.message_fr && !!errors.message_fr}
                                        helperText={touched.message_fr && errors.message_fr}
                                        sx={{ gridColumn: "span 4" }}
                                    />

                                    <Box sx={{ gridColumn: "span 1", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                        <Checkbox
                                            name={"send_es"}
                                            checked={values.send_es}
                                            onChange={(e) => setFieldValue("send_es", e.target.checked)}
                                        />
                                        <Typography variant='h5'>İspanyolca</Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj(İspanyolca)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        disabled={!values.send_es}
                                        name={"message_es"}
                                        value={values.message_es}
                                        error={!!touched.message_es && !!errors.message_es}
                                        helperText={touched.message_es && errors.message_es}
                                        sx={{ gridColumn: "span 4" }}
                                    />

                                    <Box sx={{ gridColumn: "span 1", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                        <Checkbox
                                            name={"send_ko"}
                                            checked={values.send_ko}
                                            onChange={(e) => setFieldValue("send_ko", e.target.checked)}
                                        />
                                        <Typography variant='h5'>Korece</Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj(Korece)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        disabled={!values.send_ko}
                                        name={"message_ko"}
                                        value={values.message_ko}
                                        error={!!touched.message_ko && !!errors.message_ko}
                                        helperText={touched.message_ko && errors.message_ko}
                                        sx={{ gridColumn: "span 4" }}
                                    />

                                    <Box sx={{ gridColumn: "span 1", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                        <Checkbox
                                            name={"send_zh"}
                                            checked={values.send_zh}
                                            onChange={(e) => setFieldValue("send_zh", e.target.checked)}
                                        />
                                        <Typography variant='h5'>Çince</Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj(Çince)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        disabled={!values.send_zh}
                                        name={"message_zh"}
                                        value={values.message_zh}
                                        error={!!touched.message_zh && !!errors.message_zh}
                                        helperText={touched.message_zh && errors.message_zh}
                                        sx={{ gridColumn: "span 4" }}
                                    />

                                    <Box sx={{ gridColumn: "span 1", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                        <Checkbox
                                            name={"send_id"}
                                            checked={values.send_id}
                                            onChange={(e) => setFieldValue("send_id", e.target.checked)}
                                        />
                                        <Typography variant='h5'>Endonezce</Typography>
                                    </Box>

                                    <TextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Mesaj(Endonezce)"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        multiline
                                        minRows={3}
                                        disabled={!values.send_id}
                                        name={"message_id"}
                                        value={values.message_id}
                                        error={!!touched.message_id && !!errors.message_id}
                                        helperText={touched.message_id && errors.message_id}
                                        sx={{ gridColumn: "span 4" }}
                                    />

                                    {/* <FormControl fullWidth variant="filled" sx={{ gridColumn: "span 2", marginTop: "10px" }}>
                                        <InputLabel id="filter-label">Dil</InputLabel>
                                        <Select
                                            labelId="filter-label"
                                            label="Dil"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"language"}
                                            value={values.language}
                                            error={!!touched.language && !!errors.language}
                                        >
                                            <MenuItem value={"tr"}>Türkçe</MenuItem>
                                            <MenuItem value={"en"}>İngilizce</MenuItem>
                                            <MenuItem value={"de"}>Almanca</MenuItem>
                                            <MenuItem value={"it"}>İtalyanca</MenuItem>
                                            <MenuItem value={"fr"}>Fransızca</MenuItem>
                                            <MenuItem value={"es"}>İspanyolca</MenuItem>
                                            <MenuItem value={"ko"}>Korece</MenuItem>
                                            <MenuItem value={"zh"}>Çince</MenuItem>
                                            <MenuItem value={"id"}>Endonezce</MenuItem>
                                        </Select>
                                    </FormControl> */}

                                </Box>
                                <Box display="flex" justifyContent="end" m="30px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default NotificationDialog