import { TextField } from '@mui/material';
import React, { useContext } from 'react'
import { AdminContext } from '../context/AdminContext';


function CopyCheckTextField(props) {

    const { admin } = useContext(AdminContext);

    return (
        <TextField
            {...props}
            onCopy={(e) => {
                if (admin.copy_perm === 1) {
                    return true;
                } else {
                    e.preventDefault();
                    alert("Kopyalanamaz.");
                    return false;
                }
            }}
            onDragStart={
                (e) => {
                    if (admin.copy_perm === 0) {
                        e.preventDefault();
                        alert("Kopyalanamaz.");
                    }
                }
            }
        />
    )
}

export default CopyCheckTextField