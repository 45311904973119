import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { forwardRef } from 'react';
import { useTheme } from '@mui/system';
import { tokens } from '../theme';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ open, handleClose, clickPositiveAnswer, clickNegativeAnswer, positiveAnswer, negativeAnswer, title, description }) {

  const theme = useTheme();
  const colors = tokens();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button sx={(theme.palette.mode === "dark" ? { color: "white" } : {})} onClick={() => {
          handleClose();
          clickPositiveAnswer();
        }}>{positiveAnswer}</Button>
        <Button sx={(theme.palette.mode === "dark" ? { color: "white" } : {})} onClick={() => {
          handleClose();
          clickNegativeAnswer();
        }}>{negativeAnswer}</Button>
      </DialogActions>
    </Dialog>
  );
}