import React, { useContext, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Checkbox, Dialog, DialogContent, Typography } from '@mui/material';
import CopyCheckTextField from '../../../../components/CopyCheckTextField';
import { AdminContext } from '../../../../context/AdminContext';

function KanjiWordDialog({ targetKanjiWord, kanji, dialogOpen, handleClose, handleFormSubmit }) {

    const { admin } = useContext(AdminContext);
    const [sendNotification, setSendNotification] = useState(false);

    var initialValues = {
        kanji_word: targetKanjiWord?.kanji_word ? targetKanjiWord?.kanji_word : "",
        ...(
            admin.permitted_language ? {} :
                {
                    sentence: targetKanjiWord?.sentence ? targetKanjiWord.sentence : "",
                    pronunciation: targetKanjiWord?.pronunciation ? targetKanjiWord.pronunciation : "",
                    hir: targetKanjiWord?.hir ? targetKanjiWord.hir : "",
                }
        ),
        translations: targetKanjiWord?.translations && targetKanjiWord.translations.length > 0 ? admin.permitted_language ? targetKanjiWord.translations.filter((tra) => tra.language === admin.permitted_language || tra.language === "en") : targetKanjiWord.translations : [
            {
                translation: "",
                sentence_translation: "",
                language: "tr"
            }, {
                translation: "",
                sentence_translation: "",
                language: "en"
            }, {
                translation: "",
                sentence_translation: "",
                language: "de"
            }, {
                translation: "",
                sentence_translation: "",
                language: "it"
            }, {
                translation: "",
                sentence_translation: "",
                language: "fr"
            }, {
                translation: "",
                sentence_translation: "",
                language: "es"
            }, {
                translation: "",
                sentence_translation: "",
                language: "ko"
            }, {
                translation: "",
                sentence_translation: "",
                language: "zh"
            }, {
                translation: "",
                sentence_translation: "",
                language: "id"
            }
        ]
    }

    var validationObject = {
        kanji_word: yup.string().required("zorunlu"),
        ...(admin.permitted_language ? {} :
            {
                sentence: yup.string().required("zorunlu"),
                pronunciation: yup.string().required("zorunlu"),
                hir: yup.string().required("zorunlu")
            }
        ),
        translations: yup.array().of(
            yup.object({
                translation: yup.string().required("zorunlu"),
                sentence_translation: yup.string().required("zorunlu"),
                language: yup.string().required("zorunlu")
            })
        )
    }

    const kanjiWordSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                {targetKanjiWord &&
                    <Typography variant='h3'>
                        Kelimeyi Düzenle
                    </Typography>
                }
                {!targetKanjiWord &&
                    <Typography variant='h3'>
                        Yeni Kelime
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={(values) => handleFormSubmit(values, sendNotification, kanji)}
                        initialValues={initialValues}
                        validationSchema={kanjiWordSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="50px" mb="25px">
                                    <CopyCheckTextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kelime"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"kanji_word"}
                                        value={values.kanji_word}
                                        error={!!touched.kanji_word && !!errors.kanji_word}
                                        helperText={touched.kanji_word && errors.kanji_word}
                                        disabled={!!(admin.permitted_language)}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    {!!(admin.permitted_language) &&
                                        <Box sx={{ gridColumn: "span 2" }} />
                                    }
                                    {!(admin.permitted_language) &&
                                        <CopyCheckTextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Kelime Okunuşu"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"hir"}
                                            value={values.hir}
                                            error={!!touched.hir && !!errors.hir}
                                            helperText={touched.hir && errors.hir}
                                            sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                        />
                                    }
                                    {!(admin.permitted_language) &&
                                        <CopyCheckTextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Cümle"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"sentence"}
                                            value={values.sentence}
                                            error={!!touched.sentence && !!errors.sentence}
                                            helperText={touched.sentence && errors.sentence}
                                            sx={{ gridColumn: "span 4", marginTop: "10px" }}
                                        />
                                    }
                                    {!(admin.permitted_language) &&
                                        <CopyCheckTextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Cümle Okunuşu"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"pronunciation"}
                                            value={values.pronunciation}
                                            error={!!touched.pronunciation && !!errors.pronunciation}
                                            helperText={touched.pronunciation && errors.pronunciation}
                                            sx={{ gridColumn: "span 4", marginTop: "10px" }}
                                        />
                                    }
                                    {values.translations.map((translation, i) =>
                                        <CopyCheckTextField
                                            key={`translation-word-${i}`}
                                            variant="filled"
                                            type="text"
                                            label={`Kelime(${translation.language})`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + i + "].translation"}
                                            value={values.translations[i].translation}
                                            error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.translation}
                                            helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.translation}
                                            disabled={!!(admin.permitted_language) && (admin.permitted_language !== "en" && translation.language === "en")}
                                            sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                        />
                                    )}
                                    {values.translations.map((translation, i) =>
                                        <CopyCheckTextField
                                            key={`translation-sentence-${i}`}
                                            variant="filled"
                                            type="text"
                                            label={`Cümle(${translation.language})`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + i + "].sentence_translation"}
                                            value={values.translations[i].sentence_translation}
                                            error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.sentence_translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.sentence_translation}
                                            helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.sentence_translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.sentence_translation}
                                            disabled={!!(admin.permitted_language) && (admin.permitted_language !== "en" && translation.language === "en")}
                                            sx={{ gridColumn: "span 4", marginTop: "20px" }}
                                        />
                                    )}
                                    {!targetKanjiWord && admin.notifications_perm === 1 &&
                                        <Box sx={{ gridColumn: "span 4", display: "flex", alignItems: "center", gap: "10px", marginTop: "20px" }} >
                                            <Checkbox
                                                name={"sendNotification"}
                                                checked={sendNotification}
                                                onChange={(e) => setSendNotification((prev) => !prev)}
                                            />
                                            <Typography variant='h5'>N{kanji.level} seviyesindeki kullanıcılara bildirim gönder.</Typography>
                                        </Box>
                                    }
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default KanjiWordDialog