import { Route, Routes } from "react-router-dom";
import Sidebar from "./global/Sidebar";
import { useMode } from "./theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { useContext } from "react";
import { AdminContext } from "./context/AdminContext";
import LoginPage from "./pages/LoginPage";
import UsersPage from "./pages/UsersPage";
import Topbar from "./global/Topbar";
import AdminsPage from "./pages/AdminsPage";
import KanjisPage from "./pages/KanjisPage";
import KanjiWordsPage from "./pages/KanjisPage/KanjiWordsPage";
import FaqsPage from "./pages/FaqsPage";
import ContactMessagesPage from "./pages/ContactMessagesPage";

function App() {
  const { admin, token, isLoading } = useContext(AdminContext);
  const theme = useMode();
  if (admin && token) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Topbar />
        <div className="app">
          <Sidebar />
          <main className="content pl80">
            <Routes>
              {admin.users_perm === 1 && <Route exact path="/" element={<UsersPage />} />}
              {admin.users_perm === 1 && <Route exact path="/users" element={<UsersPage />} />}
              {admin.contents_perm === 1 && <Route exact path="/kanjis" element={<KanjisPage />} />}
              {admin.contents_perm === 1 && <Route exact path="/kanjiWords" element={<KanjiWordsPage />} />}
              {admin.faqs_perm === 1 && <Route exact path="/faqs" element={<FaqsPage />} />}
              {admin.contact_messages_perm === 1 && <Route exact path="/contactMessages" element={<ContactMessagesPage />} />}
              {admin.admins_perm === 1 && <Route exact path="/admins" element={<AdminsPage />} />}
            </Routes>
          </main>
        </div>
      </ThemeProvider>
    );
  } else if (!isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <main className="content" >
            <LoginPage />
          </main>
        </div>
      </ThemeProvider>
    )
  }
}

export default App;
