import { Box, Button, IconButton, Typography } from '@mui/material'
import React from 'react'
import { tokens } from '../../../theme';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';

function FaqBox({ faq, onEditButtonClick, onDeleteButtonClick, onChangePositionButtonClick, maxPosition }) {
    const colors = tokens();
    return (
        <Box display="flex" flexDirection="column" justifyContent="space-between" sx={{ gridColumn: "span 1", border: "1px solid " + colors.grey[500], borderRadius: "20px" }}>
            <Box padding="10px" display="flex" justifyContent="space-between" alignItems="center" >
                <Typography variant="h3">{faq.title}</Typography>
            </Box>
            <Box component="hr" height="1px" width="100%" sx={{ background: "black", border: "0px", margin: "0px" }} />
            <Box display={"flex"} alignItems="top" justifyContent="space-between" padding="10px">
                <Typography variant='h5' mt="10px">{faq.answer}</Typography>
                <Box display="flex" flexDirection="column">
                    {faq.position !== 1 &&
                        <IconButton onClick={() => onChangePositionButtonClick(faq.id, faq.position, faq.position - 1)}>
                            <ArrowUpward />
                        </IconButton>
                    }
                    {faq.position !== maxPosition &&
                        <IconButton onClick={() => onChangePositionButtonClick(faq.id, faq.position, faq.position + 1)}>
                            <ArrowDownward />
                        </IconButton>
                    }
                </Box>
            </Box>
            <Box display="flex" gap="10px" padding="10px" justifyContent="end">
                <Button variant="contained" color="info" sx={{ width: "15%", borderRadius: "12px" }} onClick={() => onEditButtonClick()}>Düzenle</Button>
                <Button variant="contained" color="error" sx={{ width: "15%", borderRadius: "12px" }} onClick={() => onDeleteButtonClick()}>Sil</Button>
            </Box>
        </Box>
    )
}

export default FaqBox