import React, { useContext } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { tokens } from '../../../theme';
import CopyCheckTextField from '../../../components/CopyCheckTextField';
import CopyCheckImage from '../../../components/CopyCheckImage';
import { AdminContext } from '../../../context/AdminContext';

function KanjiDialog({ targetKanji, dialogOpen, handleClose, handleFormSubmit }) {

    const colors = tokens();

    const { admin } = useContext(AdminContext);

    var initialValues = {
        kanji: targetKanji?.kanji ? targetKanji?.kanji : "",
        ...(
            admin.permitted_language ? {} :
                {
                    onyomi: targetKanji?.onyomi ? targetKanji.onyomi : "",
                    kunyomi: targetKanji?.kunyomi ? targetKanji.kunyomi : "",
                    level: targetKanji?.level ? targetKanji.level : 5,
                    active: targetKanji ? (targetKanji.active ? targetKanji.active : 0) : 1,
                    kanjiImage: null,
                    kanji_image_link: targetKanji?.kanji_image_link ? targetKanji?.kanji_image_link : "",
                    strokeOrderGif: null,
                    stroke_order_gif_link: targetKanji?.stroke_order_gif_link ? targetKanji?.stroke_order_gif_link : ""
                }
        ),
        translations: targetKanji?.translations && targetKanji.translations.length > 0 ? admin.permitted_language ? targetKanji.translations.filter((tra) => tra.language === admin.permitted_language || tra.language === "en") : targetKanji.translations : [
            {
                translation: "",
                language: "tr"
            }, {
                translation: "",
                language: "en"
            }, {
                translation: "",
                language: "de"
            }, {
                translation: "",
                language: "it"
            }, {
                translation: "",
                language: "fr"
            }, {
                translation: "",
                language: "es"
            }, {
                translation: "",
                language: "ko"
            }, {
                translation: "",
                language: "zh"
            }, {
                translation: "",
                language: "id"
            }
        ]
    }

    var validationObject = {
        kanji: yup.string().required("zorunlu"),
        ...(admin.permitted_language ? {} :
            {
                onyomi: yup.string().required("zorunlu"),
                kunyomi: yup.string().required("zorunlu"),
                level: yup.number().min(1, "1'den düşük olamaz").max(5, "5'den büyük olamaz").required("zorunlu"),
                active: yup.number().required("zorunlu"),
                kanjiImage: yup.mixed().nullable().when('kanji_image_link', {
                    is: (kanji_image_link) => (!kanji_image_link || kanji_image_link === ""),
                    then: () => yup.string().nonNullable().required('zorunlu')
                }),
                kanji_image_link: yup.string(),
                strokeOrderGif: yup.mixed().nullable().when('stroke_order_gif_link', {
                    is: (stroke_order_gif_link) => (!stroke_order_gif_link || stroke_order_gif_link === ""),
                    then: () => yup.string().nonNullable().required('zorunlu')
                }),
                stroke_order_gif_link: yup.string()
            }
        ),
        translations: yup.array().of(
            yup.object({
                translation: yup.string().required("zorunlu"),
                language: yup.string().required("zorunlu")
            })
        )
    }

    const kanjiSchema = yup.object().shape(validationObject);

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                {targetKanji &&
                    <Typography variant='h3'>
                        Kanjiyi Düzenle
                    </Typography>
                }
                {!targetKanji &&
                    <Typography variant='h3'>
                        Yeni Kanji
                    </Typography>
                }
            </Box>
            <DialogContent>
                <Box>
                    <Formik
                        onSubmit={handleFormSubmit}
                        initialValues={initialValues}
                        validationSchema={kanjiSchema}
                    >
                        {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                            <form onSubmit={handleSubmit}>
                                {!(admin.permitted_language) &&
                                    <Box display="flex" justifyContent={"space-evenly"}>
                                        <Box textAlign="center">
                                            <Typography variant="h6">Kanji Görseli</Typography>
                                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                                {(values.kanjiImage || (values.kanji_image_link && values.kanji_image_link !== "")) && <CopyCheckImage alt='kanji' width="100px" src={values.kanjiImage ? URL.createObjectURL(values.kanjiImage) : values.kanji_image_link} />}
                                                <input id="kanjiImage" name="kanjiImage" type="file" accept=".png, .jpeg, .jpg" onChange={(event) => { setFieldValue("kanjiImage", event.target.files[0]); }} hidden />
                                            </Button>
                                        </Box>
                                        <Box textAlign="center">
                                            <Typography variant="h6">Kanji Gif</Typography>
                                            <Button variant="contained" component="label" style={{ textAlign: "center", background: colors.grey[900], height: "150px", width: "150px" }}>
                                                {(values.strokeOrderGif || (values.stroke_order_gif_link && values.stroke_order_gif_link !== "")) && <CopyCheckImage alt='stroke-order' width="100px" src={values.strokeOrderGif ? URL.createObjectURL(values.strokeOrderGif) : values.stroke_order_gif_link} />}
                                                <input id="strokeOrderGif" name="strokeOrderGif" type="file" accept=".gif" onChange={(event) => { setFieldValue("strokeOrderGif", event.target.files[0]); }} hidden />
                                            </Button>
                                        </Box>
                                    </Box>
                                }
                                <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px">
                                    <CopyCheckTextField
                                        fullWidth
                                        variant="filled"
                                        type="text"
                                        label="Kanji"
                                        onBlur={handleBlur}
                                        onChange={handleChange}
                                        name={"kanji"}
                                        value={values.kanji}
                                        error={!!touched.kanji && !!errors.kanji}
                                        helperText={touched.kanji && errors.kanji}
                                        disabled={!!(admin.permitted_language)}
                                        sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                    />
                                    <Box sx={{ gridColumn: "span 2" }} />

                                    {!(admin.permitted_language) &&
                                        <FormControl variant="filled" sx={{ gridColumn: "span 2", marginTop: "10px" }}>
                                            <InputLabel id="filter-label1">Durum</InputLabel>
                                            <Select
                                                labelId="filter-label1"
                                                label="Durum"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                name={"active"}
                                                value={values.active}
                                                error={!!touched.active && !!errors.active}
                                            >
                                                <MenuItem value={0}>Pasif</MenuItem>
                                                <MenuItem value={1}>Aktif</MenuItem>
                                            </Select>
                                        </FormControl>
                                    }

                                    {!(admin.permitted_language) &&
                                        <CopyCheckTextField
                                            fullWidth
                                            variant="filled"
                                            type="number"
                                            label="N Seviyesi"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"level"}
                                            value={values.level}
                                            error={!!touched.level && !!errors.level}
                                            helperText={touched.level && errors.level}
                                            sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                        />
                                    }
                                    {!(admin.permitted_language) &&
                                        <CopyCheckTextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Onyomi"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"onyomi"}
                                            value={values.onyomi}
                                            error={!!touched.onyomi && !!errors.onyomi}
                                            helperText={touched.onyomi && errors.onyomi}
                                            sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                        />
                                    }
                                    {!(admin.permitted_language) &&
                                        <CopyCheckTextField
                                            fullWidth
                                            variant="filled"
                                            type="text"
                                            label="Kunyomi"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"kunyomi"}
                                            value={values.kunyomi}
                                            error={!!touched.kunyomi && !!errors.kunyomi}
                                            helperText={touched.kunyomi && errors.kunyomi}
                                            sx={{ gridColumn: "span 2", marginTop: "10px" }}
                                        />
                                    }
                                    {values.translations.map((translation, i) =>
                                        <CopyCheckTextField
                                            key={`translation-${i}`}
                                            variant="filled"
                                            type="text"
                                            label={`Kanji(${translation.language})`}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            name={"translations[" + i + "].translation"}
                                            value={values.translations[i].translation}
                                            error={!!touched?.translations && !!touched?.translations[i] && !!touched?.translations[i]?.translation && !!errors?.translations && !!errors?.translations[i] && !!errors?.translations[i]?.translation}
                                            helperText={touched?.translations && touched?.translations[i] && touched?.translations[i]?.translation && errors?.translations && errors?.translations[i] && errors?.translations[i]?.translation}
                                            disabled={!!(admin.permitted_language) && (admin.permitted_language !== "en" && translation.language === "en")}
                                            sx={{ gridColumn: "span 2", marginTop: "20px" }}
                                        />
                                    )}
                                </Box>
                                <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                    <Button type="submit" color="secondary" variant="contained">
                                        <Typography variant='h5'>Kaydet</Typography>
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Box>
            </DialogContent>
        </Dialog>
    )
}

export default KanjiDialog