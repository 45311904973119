import React, { useContext, useState } from 'react'
import { Formik } from 'formik';
import * as yup from "yup";
import { Box, Button, CircularProgress, Dialog, DialogContent, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { tokens } from '../../../theme';
import axios from 'axios';
import { AdminContext } from '../../../context/AdminContext';

function BulkUploadDialog({ dialogOpen, handleClose }) {
    const colors = tokens();
    const { token } = useContext(AdminContext);
    const [isLoading, setIsLoading] = useState(false);

    var initialValues = {
        kanjiImages: null,
        kanjiStrokeOrderGifs: null,
        words: null
    }

    var validationObject = {
        kanjiImages: yup.mixed().nullable(),
        kanjiStrokeOrderGifs: yup.mixed().nullable(),
        words: yup.mixed().nullable(),
    }

    const kanjiSchema = yup.object().shape(validationObject);

    const handleFormSubmit = async (values) => {
        setIsLoading(true);
        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadBulkKanjiImage?token=${token}`, { kanjiImages: values.kanjiImages }, {
            headers: {
                "content-type": "multipart/form-data"
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });
        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadBulkKanjiStrokeOrderGif?token=${token}`, { kanjiStrokeOrderGifs: values.kanjiStrokeOrderGifs }, {
            headers: {
                "content-type": "multipart/form-data"
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {

                }
            });
        await axios.post(`${process.env.REACT_APP_API_URL}webAdmin/uploadBulkKanjiCsv?token=${token}`, { words: values.words }, {
            headers: {
                "content-type": "multipart/form-data"
            }
        })
            .catch((err) => {
                console.log("err: " + err);
            })
            .then((response) => {
                if (response && response.data.success === true && response.data.status === 201) {
                }
            });

        setIsLoading(false);
        handleClose();
    }

    return (
        <Dialog
            sx={{ margin: "0 auto" }} open={dialogOpen}
            onClose={handleClose}>
            <Box m="20px" textAlign="center">
                <Typography variant='h3'>
                    Toplu Kanji Yükle
                </Typography>
            </Box>
            <DialogContent>
                {isLoading &&
                    <Box display="flex" justifyContent="center">
                        <CircularProgress variant='indeterminate' />
                    </Box>}
                {!isLoading &&
                    <Box>
                        <Formik
                            onSubmit={handleFormSubmit}
                            initialValues={initialValues}
                            validationSchema={kanjiSchema}
                        >
                            {({ values, errors, touched, handleBlur, handleChange, handleSubmit, setFieldValue }) => (
                                <form onSubmit={handleSubmit}>
                                    <Box display="grid" gap="15px" gridTemplateColumns="repeat(4, minmax(0, 1fr))" mx="75px" mb="25px">
                                        <Box sx={{ gridColumn: "span 4" }}>
                                            <Typography variant="h5" mx="auto">Fotoğraflar:</Typography>
                                            <input
                                                id="kanjiImages"
                                                name="kanjiImages"
                                                type="file"
                                                multiple
                                                accept=".png, .jpeg, .jpg"
                                                onChange={(event) => { setFieldValue("kanjiImages", Array.from(event.target.files)); }}
                                                style={{ marginTop: "10px" }}
                                            />
                                        </Box>
                                        <Box sx={{ gridColumn: "span 4" }}>
                                            <Typography variant="h5" mx="auto">Gifler:</Typography>
                                            <input
                                                id="kanjiStrokeOrderGifs"
                                                name="kanjiStrokeOrderGifs"
                                                type="file"
                                                multiple
                                                accept=".gif"
                                                onChange={(event) => { setFieldValue("kanjiStrokeOrderGifs", Array.from(event.target.files)); }}
                                                style={{ marginTop: "10px" }}
                                            />
                                        </Box>
                                        <Box sx={{ gridColumn: "span 4", mt: "20px" }}>
                                            <Typography variant="h5" mx="auto">Excel Dosyası:</Typography>
                                            <input
                                                id="words"
                                                name="words"
                                                type="file"
                                                accept=".xlsx"
                                                onChange={(event) => { setFieldValue("words", event.target.files[0]); }}
                                                style={{ marginTop: "10px" }}
                                            />
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="end" m="20px 20px 0px 20px">
                                        <Button type="submit" color="secondary" variant="contained" onClick={() => { console.log(values) }}>
                                            <Typography variant='h5'>Kaydet</Typography>
                                        </Button>
                                    </Box>
                                </form>
                            )}
                        </Formik>
                    </Box>
                }
            </DialogContent>
        </Dialog >
    )
}

export default BulkUploadDialog